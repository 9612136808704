/*
 * Used by the global <ErrorsBoundary /> component to decide on
 * the appropriate screen and messaging to display to the user.
 */
export enum ErrorTypeEnum {
  GenericError = "GENERIC_ERROR",
  InvalidJWT = "INVALID_JWT",
  LinkExpired = "LINK_EXPIRED",
  ApiError = "API_ERROR",
  BankAuthorisationError = "BANK_AUTHORISATION_ERROR",
  BankAuthConsentExpired = "BANK_AUTHORISATION_CONSENT_EXPIRED",
  PaymentRequestError = "PAYMENT_REQUEST_ERROR",
  InstitutionError = "INSTITUTION_ERROR",
  SessionExpired = "SESSION_EXPIRED",
  Forbidden = "FORBIDDEN_ERROR",
  ClientTimeout = "CLIENT_TIMEOUT",
  BankAccountNotEligibleForScheme = "BANK_ACCOUNT_NOT_ELIGIBLE_FOR_SCHEME",
  BankAccountMismatch = "BANK_ACCOUNT_MISMATCH",
  NoValidBankAccount = "NO_VALID_BANK_ACCOUNT",
  BankAuthorisationAlreadyCompleted = "BANK_AUTHORISATION_ALREADY_COMPLETED",
  PayerCountryNotSupported = "PAYER_COUNTRY_NOT_SUPPORTED",
  BankIdVerificationTimeLimitExceeded = "BANKID_VERIFICATION_TIME_LIMIT_EXCEEDED",
  BankIdVerificationUnsuccessful = "BANKID_VERIFICATION_UNSUCCESSFULL",
  BankIdAppNotFound = "BANKID_APP_NOT_FOUND",
  ResumeUnauthorisedError = "RESUME_UNAUTHORISED_ERROR",
  BankAuthConsentRejected = "BANK_AUTHORISATION_CONSENT_REJECTED",
  InstitutionNotSupportedError = "INSTITUTION_NOT_SUPPORTED_ERROR",
  InsufficientFunds = "INSUFFICIENT_FUNDS",
}

/*
 * Represents all possible values for failureReason
 * persisted on the bankAuthorisation object.
 */
export enum BankAuthFailureReasonsEnum {
  // Set by OBG
  AccessDenied = "access_denied",
  InvalidGrant = "invalid_grant",
  ConsentStateInvalid = "consent_state_invalid",
  ConsentStateNotProvided = "consent_state_not_provided",
  ConsentRejected = "consent_rejected",
  ConfigurationError = "configuration_error",
  UncategorisedError = "uncategorized_error",
  InternalError = "internal_error",
  ServerError = "server_error",
  ProviderError = "provider_error",
  InstitutionError = "institution_error",
  TemporarilyUnavailable = "temporarily_unavailable",
  RequestNotSupported = "request_not_supported",
  RequestUriNotSupported = "request_uri_not_supported",
  InsufficientFunds = "insufficient_funds",

  // Set by PaySvc
  BankAuthorisationAlreadyCompleted = "bank_authorisation_already_completed",
  BankAccountNotEligibleForScheme = "bank_account_not_eligible_for_scheme",
  BankAccountMismatch = "bank_account_mismatch",
  NoValidBankAccount = "no_valid_bank_account",
  PayerCountryNotSupported = "payer_country_not_supported",
  InstitutionNotSupported = "institution_not_supported",
  SchemeNotSupportedByInstitution = "scheme_not_supported_by_institution",

  // Specific to PayTo
  PayToDenied = "denied",
  PayToNoAnswerFromCustomer = "NOAS",

  // Specific to Bank ID
  BankIdExpiredTransaction = "expiredTransaction",
  BankIdUserCancelled = "userCancel",
  BankIdWorkerTimeout = "gc_worker_timeout",
  BankIdNetworkError = "network_error",
}

export const mapBankAuthFailureReasonToErrorType = (
  failureReason: BankAuthFailureReasonsEnum
): ErrorTypeEnum => {
  // Sometimes PaySvc interpolates name on the reason
  if (/bank_account_mismatch/i.test(failureReason)) {
    return ErrorTypeEnum.BankAccountMismatch;
  }

  switch (failureReason) {
    case BankAuthFailureReasonsEnum.NoValidBankAccount:
      return ErrorTypeEnum.NoValidBankAccount;

    case BankAuthFailureReasonsEnum.InstitutionNotSupported:
      return ErrorTypeEnum.InstitutionNotSupportedError;

    case BankAuthFailureReasonsEnum.BankAccountNotEligibleForScheme:
    case BankAuthFailureReasonsEnum.SchemeNotSupportedByInstitution:
      return ErrorTypeEnum.BankAccountNotEligibleForScheme;

    case BankAuthFailureReasonsEnum.BankIdUserCancelled:
    case BankAuthFailureReasonsEnum.BankIdNetworkError:
      return ErrorTypeEnum.BankIdVerificationUnsuccessful;

    case BankAuthFailureReasonsEnum.BankIdExpiredTransaction:
    case BankAuthFailureReasonsEnum.BankIdWorkerTimeout:
      return ErrorTypeEnum.BankIdVerificationTimeLimitExceeded;

    case BankAuthFailureReasonsEnum.ProviderError:
    case BankAuthFailureReasonsEnum.InvalidGrant:
    case BankAuthFailureReasonsEnum.InstitutionError:
      return ErrorTypeEnum.InstitutionError;

    case BankAuthFailureReasonsEnum.BankAuthorisationAlreadyCompleted:
      return ErrorTypeEnum.BankAuthorisationAlreadyCompleted;

    case BankAuthFailureReasonsEnum.BankAccountMismatch:
      return ErrorTypeEnum.BankAccountMismatch;

    case BankAuthFailureReasonsEnum.InsufficientFunds:
      return ErrorTypeEnum.InsufficientFunds;

    case BankAuthFailureReasonsEnum.PayToNoAnswerFromCustomer:
    case BankAuthFailureReasonsEnum.ConsentRejected:
      return ErrorTypeEnum.BankAuthConsentRejected;

    case BankAuthFailureReasonsEnum.AccessDenied:
    case BankAuthFailureReasonsEnum.PayToDenied:
    case BankAuthFailureReasonsEnum.ServerError:
    case BankAuthFailureReasonsEnum.InternalError:
    case BankAuthFailureReasonsEnum.TemporarilyUnavailable:
    case BankAuthFailureReasonsEnum.RequestNotSupported:
    case BankAuthFailureReasonsEnum.RequestUriNotSupported:
    case BankAuthFailureReasonsEnum.ConsentStateInvalid:
    case BankAuthFailureReasonsEnum.ConsentStateNotProvided:
      return ErrorTypeEnum.BankAuthorisationError;

    default:
      return ErrorTypeEnum.GenericError;
  }
};

export enum PlaidErrorsEnum {
  NoAccounts = "NO_ACCOUNTS",
  InvalidLinkToken = "INVALID_LINK_TOKEN",
  InvalidCredentials = "INVALID_CREDENTIALS",
  InsufficientCredentials = "INSUFFICIENT_CREDENTIALS",
  InvalidMFA = "INVALID_MFA",
  Other = "PLAID_OTHER",
  // Plaid provide a large number of different error codes
  // and we don't have logic specific to each one;
  // the 'Other' key represents these codes.

  // For the complete list of error codes, see: https://plaid.com/docs/errors/
}

export const BankAuthFailureReasonsToShowFallback: BankAuthFailureReasonsEnum[] =
  [
    BankAuthFailureReasonsEnum.ProviderError,
    BankAuthFailureReasonsEnum.InstitutionError,
    BankAuthFailureReasonsEnum.InvalidGrant,
    BankAuthFailureReasonsEnum.ServerError,
    BankAuthFailureReasonsEnum.ConsentRejected,
    BankAuthFailureReasonsEnum.TemporarilyUnavailable,
    BankAuthFailureReasonsEnum.RequestNotSupported,
    BankAuthFailureReasonsEnum.RequestUriNotSupported,
    BankAuthFailureReasonsEnum.AccessDenied,
  ];
