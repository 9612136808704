import { useContext, useEffect } from "react";
import { useRouter } from "next/router";
import { GlobalState } from "src/state";
import LoadingGif from "src/components/shared/LoadingGif";
import {
  Action,
  ActionStatus,
  ActionType,
  Adapter,
  BankAuthorisationResource,
  BillingRequestFlowResource,
  BillingRequestResource,
  BillingRequestsStatus,
  Institution,
} from "@gocardless/api/dashboard/types";
import { Logger } from "src/common/logger";
import {
  isSepaIBPScheme,
  usesOpenBankingGatewayAisAdapter,
  getBankAuthorisationAdapter,
  isBillingRequestSuccessful,
  shouldRedirectToDualSigFlow,
  requiresAction,
  shouldSkipSuccessScreen,
  usesBankIdAisAdapter,
} from "src/common/utils";
import { isRole } from "src/common/config";
import { Role } from "src/common/environments";

// Routes enumerates all routes provided by the next.js app. Each route should
// have a matching file in src/pages.
export enum Routes {
  Flow = "/flow",
  CollectAmount = "/collect-amount",
  CollectCustomerDetails = "/collect-customer-details",
  CollectBankAccount = "/collect-bank-account",
  Consent = "/consent",
  BankPlaidConnect = "/bank-plaid-connect",
  AutogiroBankIdConnect = "/autogiro-bankid-connect",
  AutogiroBankId = "/autogiro-bankid",
  BankPlaidLink = "/bank-plaid-link",
  BankConnectRequest = "/bank-connect-request",
  BankAccountSelect = "/bank-account-select",
  BankSelect = "/bank-select",
  BankConfirm = "/bank-confirm",
  BankConnect = "/bank-connect",
  InterstitialMobileConnect = "/interstitial-mobile-connect",
  BankWait = "/bank-wait",
  Fulfil = "/fulfil",
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Success = "/success",
  Return = "/return",
  MigrationOptOut = "/migration-optout",

  /**
   * created this route for testing the dropin functionality.
   * This should be available only for sandbox and staging
   */
  Dropin = "/dropin",
}

// Router initialises the app by using the Billing Request and Billing Request
// Flow to pick the most appropriate next state for the app. It is hooked into
// the Flow route, and we defer to this router whenever other routes think they
// shouldn't be running.
export const Router = ({ children }: { children: React.ReactNode }) => {
  const router = useRouter();
  const {
    billingRequest,
    billingRequestFlow,
    selectedInstitution: institution,
    bankAuthorisation,
    bankDetailsConfirmed,
    mandateMigration,
  } = useContext(GlobalState);

  const log = Logger("Router", {
    router_route: router.route,
  });
  const params = router.query;
  const qrCodeConfirm = params["qr-code"] === "true";
  // We track the route that we first arrive at so that we can decide to show
  // elements depending on whether this is the first page the payer sees. We
  // store the inital route as a query parameter so that the state is maintained
  // even if the page is refreshed.
  //
  // Ignore for MigrationOptOut route
  useEffect(() => {
    // do not handle any rerouting logic until the router is actually ready
    if (!router.isReady) {
      return;
    }
    if (router.query.initial || router.route === Routes.MigrationOptOut) {
      return;
    }
    // Ignore routes that are transient
    switch (router.route) {
      case Routes.Flow:
        break;
      default:
        router.replace({
          pathname: router.pathname,
          query: {
            ...router.query,
            initial: router.route,
          },
        });
    }
  }, [router]);

  // Whenever the URL path changes, either via a push or through browser
  // redirect, verify we're allowed there.
  //
  // Ignore for MigrationOptOut route
  useEffect(() => {
    if (router.route === Routes.MigrationOptOut) {
      if (!mandateMigration) {
        return log({
          message: "waiting for router to initialise",
        });
      }
      return;
    }

    if (!router.route || !billingRequest || !billingRequestFlow) {
      return log({
        message: "waiting for router to initialise",
      });
    }
    let reason: string;

    if (Object.values(Routes).includes(router.route as Routes)) {
      if (
        canVisit({
          route: router.route as Routes,
          billingRequest,
          billingRequestFlow,
          institution,
          bankAuthorisation,
          bankDetailsConfirmed,
          qrCodeConfirm,
        })
      ) {
        return log({
          message: "route is permitted",
        });
      }

      reason = "route is not permitted";
    } else {
      reason = "route is not recognised";
    }

    const preferred = getPreferred({
      billingRequest,
      billingRequestFlow,
      institution,
      bankAuthorisation,
      bankDetailsConfirmed,
      route: router.route as Routes,
    });

    log({
      message: "routing to preferred",
      preferred,
      reason,
    });

    // redirect to dual sign flow url
    if (shouldRedirectToDualSigFlow(billingRequest, billingRequestFlow)) {
      window.location.href = preferred;
      return;
    }

    router.push({
      pathname: preferred,
      query: router.query,
    });
    // Note: Do not add 'router' to deps here, as it causes a an issue with refreshing
  }, [
    billingRequest,
    billingRequestFlow,
    institution,
    bankAuthorisation,
    bankDetailsConfirmed,
    mandateMigration,
    qrCodeConfirm,
  ]);

  if (router.route === Routes.MigrationOptOut) {
    if (!mandateMigration) {
      return <LoadingGif />;
    }
    return <>{children}</>;
  }

  // When the router component is rendered it does not run any useEffect
  // immediately, which means our logic to prevent visiting inappropriate routes
  // may not have been applied.
  //
  // This guard prevents us from showing the requested route until our guard has
  // been applied.
  if (!router.route || !billingRequest || !billingRequestFlow) {
    return <LoadingGif />;
  }

  return <>{children}</>;
};

// Some components should only be displayed if the route we're showing was the
// first route we landed on. Examples are the Billing Request description, which
// we don't want to repeat on each page.
//
// Use this component to conditionally display components depending on whether
// this is the initial page.
//
// Eg.
//
// <ShowIfInitialRoute>
//   <BillingRequestDescription />
// </ShowIfInitialRoute>
//
// Warning: this component will only work inside of a <Router>!
export const ShowIfInitialRoute = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const router = useRouter();

  if (router?.query?.initial === router?.route) {
    return <> {children} </>;
  }

  return null;
};

export const canVisit = ({
  route,
  billingRequest,
  billingRequestFlow,
  institution,
  bankAuthorisation,
  bankDetailsConfirmed,
  qrCodeConfirm,
}: {
  route: Routes;
  billingRequest: BillingRequestResource;
  billingRequestFlow: BillingRequestFlowResource;
  institution?: Institution | null;
  bankAuthorisation?: BankAuthorisationResource;
  bankDetailsConfirmed?: boolean;
  qrCodeConfirm?: boolean;
}): boolean => {
  const log = Logger("canVisit", {
    route,
  });
  if (route === Routes.Flow) {
    log({
      message: "flow is an alias for the next route, so no, we can't visit it",
    });
    return false;
  }

  if (route === Routes.InterstitialMobileConnect) {
    return true;
  }

  // Return is the terminal route, and should be reachable in all
  // circumstances, unless there has been an unrecoverable error.
  if (route === Routes.Return) {
    log({
      message: "can always visit return",
    });
    return true;
  }

  // Whenever someone tries to access the specific page of the already completed
  // flow we need to always show them success screen in order to avoid the data
  // edited.
  if (route !== Routes.Success && isBillingRequestSuccessful(billingRequest)) {
    log({
      message: "billingRequest is already fulfilled, can't visit here",
    });
    return false;
  }

  if (route === Routes.CollectAmount) {
    const bankAuthorisationAction = findAction(
      billingRequest,
      ActionType.BankAuthorisation,
      null
    );

    if (bankAuthorisationAction?.status === ActionStatus.Completed) {
      log({
        message: "bank authorisation action is completed, can't visit here",
      });
      return false;
    }
    return true;
  }

  if (route === Routes.CollectCustomerDetails) {
    if (isLocked(billingRequestFlow, ActionType.CollectCustomerDetails)) {
      log({
        message: "collect_customer_details is locked, can't visit here",
      });
      return false;
    }

    return true;
  }

  if (route === Routes.CollectBankAccount) {
    if (isLocked(billingRequestFlow, ActionType.CollectBankAccount)) {
      log({
        message: "collect_bank_account is locked, can't visit here",
      });
      return false;
    }

    return true;
  }

  if (route === Routes.BankConfirm) {
    // We only want to route here when going through a mandate-only or
    // fallback flow. This is because in the dual flow, this step is
    // already handled by the `getPreferred` method.
    //
    // For dual flow or payment_request flow we can show confirm page because
    // the status will be moved to ready_to_fulfil after the bank_authorisation step.
    // Unless fallback occured, in this case we need to show bank confirmation step.
    //
    // This logic will change when we implement AIS or when we use bank_authorisation
    // action to complete collect_bank_account and collect_customer
    const mandateOnlyFlow =
      billingRequest.mandate_request && !billingRequest.payment_request;
    if (billingRequest.fallback_occurred || mandateOnlyFlow) {
      if (
        !isReady(
          billingRequest,
          findAction(billingRequest, ActionType.ConfirmPayerDetails, null)
        )
      ) {
        log({
          message: "dependent actions are not completed",
          billing_request_status: billingRequest?.status,
        });
        return false;
      } else {
        return true;
      }
    }
  }

  const bankAuthorisationAction = findAction(
    billingRequest,
    ActionType.BankAuthorisation,
    null
  );

  switch (route) {
    case Routes.BankSelect:
    case Routes.BankConfirm:
    case Routes.BankConnect:
    case Routes.BankWait:
    case Routes.BankAccountSelect:
    case Routes.BankConnectRequest:
      if (!bankAuthorisationAction) {
        log({
          message:
            "bank_authorisation isn't available for this billing request",
        });
        return false;
      }

      if (
        // For the Plaid flow, bank authorisation is completed on their platform,
        // so the payer doesn't need to go through any of these routes
        // (we take them straight to BankPlaidConnect).
        bankAuthorisationAction?.bank_authorisation?.adapter ===
        Adapter.PlaidAis
      ) {
        log({
          message: "wrong bank_authorisation adapter for this route",
        });
        return false;
      }

      if (route === Routes.BankConfirm) {
        // Because we want to be able to go to the confirm page for all Prefilled PayTo
        // flows, we have added this check to ignore billing request where no
        // institution exist and is not required
        if (
          !institution &&
          requiresAction({
            billingRequest,
            actionType: ActionType.SelectInstitution,
          })
        ) {
          log({
            message: "BankConfirm requires institution, but we don't have one",
          });
          return false;
        }
      }

      // We can only visit the BankConnectRequest page if an institution has
      // been selected and the bank authorisation adapter is OpenBankingGatewayAis
      if (route === Routes.BankConnectRequest) {
        if (!institution) {
          log({
            message:
              "BankConnectRequest requires institution, but we don't have one",
          });
          return false;
        }
        if (!usesOpenBankingGatewayAisAdapter(billingRequest)) {
          log({
            message: "wrong bank_authorisation adapter for this route",
          });
          return false;
        }

        if (
          bankAuthorisation?.last_visited_at &&
          bankAuthorisation?.bank_accounts?.length
        ) {
          log({
            message:
              "bankAuthorisation includes bank accounts, one of them can now be authorised",
          });
          return false;
        }

        if (bankAuthorisationAction?.status === ActionStatus.Completed) {
          log({
            message: "bankAuthorisation is already completed",
          });
          return false;
        }
      }

      if (route === Routes.BankWait) {
        if (!bankAuthorisation) {
          log({
            message:
              "BankWait requires bankAuthorisation, but we don't have one",
          });
        }

        if (usesOpenBankingGatewayAisAdapter(billingRequest)) {
          if (
            bankAuthorisation?.last_visited_at &&
            bankAuthorisation?.bank_accounts?.length
          ) {
            log({
              message:
                "bankAuthorisation includes bank accounts, one of them can now be authorised",
            });
            return false;
          }
        }
      }

      // User shouldn't be able to land onto /bank-connect directly,
      // they can only access the route:
      // - via /bank-confirm if this is a PIS flow
      // - via /autogiro-bankid-connect if this is a Autogiro BankId flow
      // - via /bank-connect-request if this is an AIS flow.

      if (route === Routes.BankConnect) {
        let message;

        if (usesOpenBankingGatewayAisAdapter(billingRequest)) {
          if (!institution) {
            message =
              "BankConnect requires institution for AIS, but we don't have one";

            log({ message });
            return false;
          }

          if (
            bankAuthorisation?.last_visited_at &&
            bankAuthorisation?.bank_accounts?.length
          ) {
            message =
              "bankAuthorisation includes bank accounts, one of them can now be authorised";

            log({ message });
            return false;
          }

          return true;
        }

        if (usesBankIdAisAdapter(billingRequest)) {
          if (!bankAuthorisation) {
            return false;
          }
          return true;
        }

        // for mobile views we want to allow QR Code users to navigate directly
        // to the BankConnect screen. We can still confirm these users will
        // still see the bank confirm screen.
        if (!bankDetailsConfirmed && !qrCodeConfirm) {
          message = "bank details have not been confirmed yet";

          log({ message });
          return false;
        }
      }

      if (route === Routes.BankAccountSelect) {
        if (!usesOpenBankingGatewayAisAdapter(billingRequest)) {
          log({
            message: "wrong bank_authorisation adapter for this route",
          });
          return false;
        }

        if (!bankAuthorisation?.bank_accounts?.length) {
          log({
            message: "bank_accounts field is empty",
          });
          return false;
        }
        if (bankAuthorisation.authorised_at) {
          log({
            message: "bank_authorisation is already authorised",
          });
          return false;
        }
      }

      return true;
    case Routes.AutogiroBankIdConnect:
      if (
        bankAuthorisationAction?.bank_authorisation?.adapter !==
        Adapter.BankidAis
      ) {
        log({
          message: "wrong bank_authorisation adapter for this route",
        });
        return false;
      }
      if (
        !isReady(
          billingRequest,
          findAction(billingRequest, ActionType.BankAuthorisation, null)
        )
      ) {
        log({
          message: "dependent action is incomplete",
        });
        return false;
      }
      return true;
    case Routes.BankPlaidLink:
    case Routes.BankPlaidConnect:
      if (!bankAuthorisationAction) {
        log({
          message:
            "bank_authorisation isn't available for this billing request",
        });
        return false;
      }

      if (
        bankAuthorisationAction?.bank_authorisation?.adapter !==
        Adapter.PlaidAis
      ) {
        log({
          message: "wrong bank_authorisation adapter for this route",
        });
        return false;
      }

      if (bankAuthorisationAction.status === ActionStatus.Completed) {
        log({
          message: "bank_authorisation action is already completed",
        });
        return false;
      }
      return true;
    case Routes.Fulfil:
      if (billingRequest.status !== BillingRequestsStatus.ReadyToFulfil) {
        log({
          message: "billingRequest is not ready_to_fulfil",
          billing_request_status: billingRequest?.status,
        });

        return false;
      }

      return true;

    case Routes.Success:
      if (!isBillingRequestSuccessful(billingRequest)) {
        log({
          message: "billingRequest is not fulfilled",
          billing_request_status: billingRequest?.status,
        });

        return false;
      }

      return true;
  }

  return false;
};

export const getPreferred = ({
  billingRequest,
  billingRequestFlow,
  institution,
  bankAuthorisation,
  bankDetailsConfirmed,
  route,
}: {
  billingRequest: BillingRequestResource;
  billingRequestFlow: BillingRequestFlowResource;
  bankAuthorisation?: BankAuthorisationResource;
  institution?: Institution | null;
  bankDetailsConfirmed?: boolean;
  route?: Routes;
}): Routes => {
  const log = Logger("getPreferred");

  switch (billingRequest.status) {
    case BillingRequestsStatus.Fulfilled:
    case BillingRequestsStatus.Fulfilling:
      if (shouldRedirectToDualSigFlow(billingRequest, billingRequestFlow)) {
        return billingRequest.sign_flow_url as Routes;
      }
      if (shouldSkipSuccessScreen(billingRequestFlow)) {
        return Routes.Return;
      }

      return Routes.Success;

    // ConfirmPayerDetails action will always be required
    // so the only time BRF will handle this status is on
    // clicking confirm button on confirm page
    // after which we call fulfill endpoint
    case BillingRequestsStatus.ReadyToFulfil:
      // this is for BRs created through redirect flow(HPP route) via api
      // where we need to route the user to success callback url
      if (!billingRequestFlow.auto_fulfil) {
        return Routes.Return;
      }

      // If someone lands on BRF with BR status as ready_to_fulfil,
      // should fulfil it and take them to Success page
      return Routes.Fulfil;

    case BillingRequestsStatus.Pending:
      break;

    // TODO: We don't handle Cancelled
    default:
      throw Error("unhandled Billing Request status");
  }

  // Evaluate whether an action is ready, based on several factors. Log whenever
  // we exclude an action and why, so we can debug.
  const getReady = (actionType: ActionType): Action | undefined => {
    const action = findAction(billingRequest, actionType, null);
    if (!action) {
      log({
        action_type: actionType,
        message: "action was not found in billing request",
      });
      return;
    }

    if (isLocked(billingRequestFlow, actionType)) {
      log({
        action_type: actionType,
        message: "action is locked",
      });

      return;
    }

    if (!isReady(billingRequest, action)) {
      log({
        action_type: actionType,
        message: "action is not ready",
      });

      return;
    }

    return action;
  };

  const getReadyAndRequired = (actionType: ActionType): Action | undefined => {
    const action = getReady(actionType);
    if (action && !action.required) {
      log({
        action_type: actionType,
        message: "action is not required, skipping",
      });
      return;
    }

    return action;
  };

  // Gradually assign preferred, as we find greater preferences.
  let preferred: Routes | undefined;
  let action: Action | undefined;

  if ((action = getReadyAndRequired(ActionType.CollectAmount))) {
    if (!preferred && action.status === ActionStatus.Pending) {
      return Routes.CollectAmount;
    }
  }

  // Always prefer collecting customer details first, as this allows us to
  // capture contact details before we possibly lose the customer.
  if ((action = getReadyAndRequired(ActionType.CollectCustomerDetails))) {
    if (!preferred && action.status === ActionStatus.Pending) {
      return Routes.CollectCustomerDetails;
    }
  }

  if ((action = getReadyAndRequired(ActionType.SelectInstitution))) {
    // for sandbox environment we should show institution list if institution is required
    // as institution here defines different outcomes of bank authorisation process
    //
    // for all flows we should show institution list if institution is required
    // except IBP in Germany, as select institution action will be completed as a part
    // of collect-bank-account action without manual choice of the institution
    // This is because we can accurately lookup the institution
    // from the bank account details for DE accounts.
    if (isRole(Role.sandbox) || !isSepaIBPScheme(billingRequest)) {
      if (!preferred && action.status === ActionStatus.Pending) {
        return Routes.BankSelect;
      }
    }
  }

  // Note, `CollectBankAccount` is the best choice for the fallback flow, so we
  // can return early here if the other conditions are met. If we're not in the
  // fallback flow then we'll only assign `preferred` rather than returning, as
  // there is likely a 'better' route match further on.
  if ((action = getReadyAndRequired(ActionType.CollectBankAccount))) {
    if (!preferred && action.status === ActionStatus.Pending) {
      if (billingRequest.fallback_occurred) {
        return Routes.CollectBankAccount;
      } else {
        preferred = Routes.CollectBankAccount;
      }
    }
  }

  if ((action = getReadyAndRequired(ActionType.BankAuthorisation))) {
    if (action.status === ActionStatus.Pending) {
      // Find all the actions bank authorisation can complete.
      const completes =
        findAction(billingRequest, ActionType.BankAuthorisation, null)
          ?.completes_actions || [];

      // Two cases here:
      // - If we require this action, and we haven't got an alternate preference
      // - Bank authorisation can complete other actions
      //
      // If either is true, we should attempt to process the action.
      if (action && (!preferred || completes.length > 0)) {
        const institutionRequired = Boolean(
          action.requires_actions?.includes("select_institution")
        );
        const institutionPresentOrNotRequired =
          !institutionRequired || !!institution;

        switch (action.bank_authorisation?.adapter) {
          // For open_banking_gateway_pis, there is one entrypoint:
          //
          // [CollectBankDetails, BankSelect] -> BankConfirm -> BankConnect -> BankWait
          //
          // If we're asking for a preferred route, it means we want to reset to a
          // sensible entrypoint. That means we shouldn't go to either BankSelect or
          // BankWait, as both of those routes are secondary to the first.
          case Adapter.OpenBankingGatewayPis:
            return getRouteForPISAdapter({
              billingRequest,
              institutionPresentOrNotRequired,
              bankAuthorisation,
              bankDetailsConfirmed: isBankDetailsConfirmed(
                billingRequest,
                bankDetailsConfirmed
              ),
            });

          case Adapter.BankPayRecurring:
            return getRouteForBankPayRecurringAdapter({
              route,
              institutionPresent: Boolean(institution),
              institutionRequired,
              bankDetailsConfirmed: isBankDetailsConfirmed(
                billingRequest,
                bankDetailsConfirmed
              ),
            });

          default:
            return getRouteForAisAdapter({
              billingRequest,
              institutionPresentOrNotRequired,
              bankAuthorisation,
            });
        }
      }
    }
  }

  if ((action = getReady(ActionType.ConfirmPayerDetails))) {
    if (!preferred && action.status === ActionStatus.Pending) {
      return Routes.BankConfirm;
    }
  }

  // It is always possible to visit Return, as that is the terminal 'goodbye'
  // route.
  return preferred || Routes.Return;
};

const getRouteForPISAdapter = ({
  billingRequest,
  institutionPresentOrNotRequired,
  bankAuthorisation,
  bankDetailsConfirmed,
}: {
  billingRequest: BillingRequestResource;
  institutionPresentOrNotRequired: boolean;
  bankAuthorisation?: BankAuthorisationResource;
  bankDetailsConfirmed?: boolean;
}): Routes => {
  if (institutionPresentOrNotRequired) {
    // If we've already selected institution(if needed) and confirmed it,
    // then go to BankConnect
    if (bankDetailsConfirmed) {
      return Routes.BankConnect;
    }

    // This is needed for IBP in DE as we want to show
    // 'waiting to hear back from your bank' message if payer has visited his bank once
    // and after that revisits the BRF flow again.
    // This is to avoid taking multiple payments from their account and we get late
    // response for some germany banks.
    if (
      isSepaIBPScheme(billingRequest) &&
      !!bankAuthorisation?.last_visited_at
    ) {
      return Routes.BankWait;
    }
    return Routes.BankConfirm;
  }
  return Routes.BankSelect;
};

const getRouteForAisAdapter = ({
  billingRequest,
  institutionPresentOrNotRequired,
  bankAuthorisation,
}: {
  billingRequest: BillingRequestResource;
  institutionPresentOrNotRequired: boolean;
  bankAuthorisation?: BankAuthorisationResource;
}) => {
  const adapter = getBankAuthorisationAdapter(billingRequest);

  switch (
    adapter as Extract<
      Adapter,
      Adapter.OpenBankingGatewayAis | Adapter.PlaidAis | Adapter.BankidAis
    >
  ) {
    case Adapter.OpenBankingGatewayAis: {
      if (bankAuthorisation?.bank_accounts?.length) {
        return Routes.BankAccountSelect;
      }

      // If an institution has already been selected, or a selection isn't required,
      // we can navigate to BankConnectRequest.

      if (institutionPresentOrNotRequired) {
        return Routes.BankConnectRequest;
      }

      return Routes.BankSelect;
    }

    // For Plaid, we can go straight to the connect page.
    case Adapter.PlaidAis:
      return Routes.BankPlaidConnect;
    case Adapter.BankidAis:
      return Routes.AutogiroBankIdConnect;
  }
};

const getRouteForBankPayRecurringAdapter = ({
  route,
  institutionPresent,
  institutionRequired,
  bankDetailsConfirmed,
}: {
  route?: Routes;
  institutionPresent: boolean;
  institutionRequired: boolean;
  bankDetailsConfirmed: boolean;
}): Routes => {
  // if bank authorisation has url to connect
  if (institutionRequired) {
    if (institutionPresent && bankDetailsConfirmed) {
      // if we've already selected institution(if needed) and confirmed it,
      // then go to BankConnect
      return Routes.BankConnect;
    }

    if (institutionPresent) {
      // We've already selected one, so we go right to BankConfirm.
      return Routes.BankConfirm;
    }
    return Routes.BankSelect;
  } else {
    // we want to show payers the confirmation screen in case when
    // integrator provided us their customer details and bank accounts
    // so payers could see the summary of their agreements
    // in the initial entry point from authorisation link
    // and trigger bank authorisation process
    if (route === Routes.Flow) {
      return Routes.BankConfirm;
    }
    return Routes.BankWait;
  }
};

// findAction searches the billing request for an action that matches both type
// and status.
export const findAction = (
  billingRequest: BillingRequestResource,
  actionType: ActionType,
  actionStatus: ActionStatus | null
): Action | undefined => {
  if (!billingRequest || !billingRequest.actions) {
    return undefined;
  }

  return billingRequest.actions.find((action) => {
    if (action.type === actionType) {
      if (actionStatus) {
        return action.status === actionStatus;
      }

      return true;
    }

    return false;
  });
};

// isReady returns true if the given action is ready to be processed.
const isReady = (
  billingRequest: BillingRequestResource,
  action?: Action
): boolean => {
  if (!action) {
    return false;
  }

  // We expect this to always be an array at this point.
  if (!action.requires_actions) {
    throw new Error("requires_actions is not defined");
  }

  for (const dependency of action.requires_actions) {
    if (
      !findAction(
        billingRequest,
        dependency as ActionType,
        ActionStatus.Completed
      )
    ) {
      return false; // if a required dependency isn't complete, we're not ready
    }
  }

  return true;
};

// isLocked defines whether an action is locked, given the flow configuration.
export const isLocked = (
  billingRequestFlow: BillingRequestFlowResource,
  actionType: ActionType
) => {
  switch (actionType) {
    case ActionType.CollectCustomerDetails:
      return billingRequestFlow.lock_customer_details;
    case ActionType.CollectBankAccount:
      return billingRequestFlow.lock_bank_account;
  }

  return false;
};

const isBankDetailsConfirmed = (
  billingRequest: BillingRequestResource,
  bankDetailsConfirmed?: boolean
): boolean => {
  const confirmPayerDetailsAction = findAction(
    billingRequest,
    ActionType.ConfirmPayerDetails,
    null
  );

  if (confirmPayerDetailsAction?.required === true) {
    return (
      confirmPayerDetailsAction?.status === ActionStatus.Completed &&
      Boolean(bankDetailsConfirmed)
    );
  }
  return Boolean(bankDetailsConfirmed);
};
